<template>
  <div>
    <c-search-box>
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="회의기간"
            defaultStart="-3M"
            defaultEnd="0"
            name="period"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <c-tab
      type="tabcard"
      align="left"
      :inlineLabel="true"
      :tabItems="tabItems"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :searchParam.sync="searchParam"
          :tabParam.sync="tabParam"
          :proceedingsTypeCd="tab.proceedingsTypeCd"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'proceedings',
  data() {
    return {
      searchParam: {
        plantCd: '',
        proceedingsTypeCd: null,
        committeeTypeCd: '3',
        startYmd: '',
        endYmd: '',
      },
      period: [],
      editable: true,
      tab: '3',
      tabItems: [
        { name: '3', icon: 'folder', label: '본안', component: () => import(`${'./proceedingsMainAgenda.vue'}`), proceedingsTypeCd: 'SPA0000001' },
        { name: '4', icon: 'folder', label: '실무안', component: () => import(`${'./proceedingsWorkingPlan.vue'}`), proceedingsTypeCd: 'SPA0000005' },
      ],
      tabParam: {
        committeeTypeCd: '3',
        title: '본안', 
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting 
    },
    tabClick(tab) {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.searchParam.committeeTypeCd = tab.name;
      this.tabParam.committeeTypeCd = tab.name;
      this.tabParam.title = tab.label;
    }
  }
};
</script>
